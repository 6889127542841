import Image from "next/image";
import { useUserLocation } from "@/context/UserLocationContext";
import React, { useState, useRef, useEffect } from "react";
import { fetchCategory, fetchData } from "../api/search";
import Link from "next/link";
import world_map from "@/assets/images/world_map.svg";
import slugify from 'slugify'; // Importing slugify for more user-friendly URLs

interface SearchResult {
  // Define the structure of each item in searchResultsLocation
  d: string; // Adjust type if 'd' is of a different type
  // Add other properties as needed
  c: string;
}

const Hero = () => {
  const [searchLocation, setSearchLocation] = useState({
    location: "",
    result: {},
  });
  const [inputModified, setInputModified] = useState(false);

  const [searchCategory, setSearchCategory] = useState({
    category: "",
    result: {},
  });

  const [searchResultsLocation, setSearchResultsLocation] = useState<
    SearchResult[]
  >([]);
  const [searchResultsCategory, setSearchResultsCategory] = useState<
    SearchResult[]
  >([]);

  const userLocation = useUserLocation();

  //console.log(searchLocation);

  useEffect(() => {
    async function setSerachlocation() {
      try {
        const response = await fetchData(searchLocation.location);
        if (response.success && searchLocation.location.length > 0) {
          setSearchResultsLocation(response.data.location);
        } else {
          setSearchResultsLocation([]);
        }
        // console.log("location", response);
      } catch (error) {
        console.error("Error fetching data:", error);
        setSearchResultsLocation([]);
      }
    }

    const id = setTimeout(() => {
      if (searchLocation.location.length > 0) {
        setSerachlocation();
      } else {
        setSearchResultsLocation([]);
      }
    }, 100);
    return () => {
      clearTimeout(id);
      setSearchResultsLocation([]);
    };
  }, [searchLocation.location]);

  // useEffect(() => {
  //   async function setSerachCategory() {
  //     try {
  //       const response = await fetchCategory(searchCategory.category);
  //       if (response.success) {
  //         setSearchResultsCategory(response.data.categories);
  //       } else {
  //         setSearchResultsCategory([]);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setSearchResultsCategory([]);
  //     }
  //   }

  //   const id = setTimeout(() => {
  //     if (searchCategory.category.length > 0) {
  //       setSerachCategory();
  //     } else {
  //       setSearchResultsCategory([]);
  //     }
  //   }, 100);

  //   return () => {
  //     clearTimeout(id);
  //     setSearchResultsCategory([]);
  //   };
  // }, [searchCategory.category]);

  useEffect(() => {
    async function setSerachCategory() {
      try {
        const response = await fetchCategory(searchCategory.category);
        if (response.success) {
          setSearchResultsCategory(response.data.categories);
          
          // Check if the user's input exactly matches a category name
          const exactMatch = response.data.categories.find(
            (cat: { c: string; }) => cat.c.toLowerCase() === searchCategory.category.toLowerCase()
          );
  
          if (exactMatch) {
            // Auto-fill the input if an exact match is found
            setSearchCategory({
              category: exactMatch.c,
              result: exactMatch,
            });
            setHighlightedCatagory(-1); // Reset highlighting if needed
          }
        } else {
          setSearchResultsCategory([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setSearchResultsCategory([]);
      }
    }
  
    const id = setTimeout(() => {
      if (searchCategory.category.length > 0) {
        setSerachCategory();
      } else {
        setSearchResultsCategory([]);
      }
    }, 100);
  
    return () => {
      clearTimeout(id);
      setSearchResultsCategory([]);
    };
  }, [searchCategory.category]);
  
  const [highlightedCatagory, setHighlightedCatagory] = useState(-1);
  
  const handleKeyboardNavigation = (e: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedCatagory((prevIndex) =>
        prevIndex < searchResultsCategory.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedCatagory((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (
        highlightedCatagory >= 0 &&
        highlightedCatagory < searchResultsCategory.length
      ) {
        handleItemSelection(searchResultsCategory[highlightedCatagory]);
      }
    }
  };
  
  const handleItemSelection = (result: SearchResult) => {
    setSearchCategory({
      category: result.c || "", // Ensure category is either valid or an empty string
      result: result,
    });
    setSearchResultsCategory([]);
    setHighlightedCatagory(-1);
  };
  
  const clearCatagoryInput = () => {
    setSearchCategory({
      category: "", // Clear both user input and default value
      result: {},
    });
    setSearchResultsCategory([]);
  };

  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const handleKeyDown = (e: { key: string; preventDefault: () => void }) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) =>
        prevIndex < searchResultsLocation.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (
        highlightedIndex >= 0 &&
        highlightedIndex < searchResultsLocation.length
      ) {
        selectItem(searchResultsLocation[highlightedIndex]);
      }
    }
  };

  const selectItem = (result: SearchResult) => {
    setSearchLocation({
      location: result.d || "",
      result: result,
    });
    setSearchResultsLocation([]);
    setHighlightedIndex(-1);
  };

  // const [highlightedCatagory, setHighlightedCatagory] = useState(-1);

  // const handleKeyboardNavigation = (e: {
  //   key: string;
  //   preventDefault: () => void;
  // }) => {
  //   if (e.key === "ArrowDown") {
  //     e.preventDefault();
  //     setHighlightedCatagory((prevIndex) =>
  //       prevIndex < searchResultsCategory.length - 1 ? prevIndex + 1 : prevIndex
  //     );
  //   } else if (e.key === "ArrowUp") {
  //     e.preventDefault();
  //     setHighlightedCatagory((prevIndex) =>
  //       prevIndex > 0 ? prevIndex - 1 : prevIndex
  //     );
  //   } else if (e.key === "Enter") {
  //     e.preventDefault();
  //     if (
  //       highlightedCatagory >= 0 &&
  //       highlightedCatagory < searchResultsCategory.length
  //     ) {
  //       handleItemSelection(searchResultsCategory[highlightedCatagory]);
  //     }
  //   }
  // };

  // const handleItemSelection = (result: SearchResult) => {
  //   setSearchCategory({
  //     category: result.c || "", // Ensure category is either valid or an empty string
  //     result: result,
  //   });
  //   setSearchResultsCategory([]);
  //   setHighlightedCatagory(-1);
  // };

  // function setRedirectSlug(objlocation: any, objcategroy: any) {
  //   try {
  //     // Assuming searchCategory.result and searchLocation.result are defined elsewhere
  //     let strlast = `${objcategroy.result.s}`; // Adjust to use objcategroy or searchCategory.result depending on your structure
  //     let strdir = "";

  //     if (objlocation.result.cy_s) {
  //       strlast = `list-of-${strlast}-in-${objlocation.result.cy_s}`;
  //       strdir = `${objlocation.result.c_s}/${objlocation.result.s_s}/`;
  //     } else if (objlocation.result.s_s) {
  //       strlast = `list-of-${strlast}-in-${objlocation.result.s_s}`;
  //       strdir = `${objlocation.result.c_s}/`;
  //     } else if (objlocation.result.c_s) {
  //       strlast = `list-of-${strlast}-in-${objlocation.result.c_s}`;
  //     }

  //     return `b2b-database/${strdir}${strlast}`;
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     // Handle or rethrow the error as needed
  //     throw error; // Example: Rethrow the error to propagate it further
  //   }
  // }

  // function setRedirectLocationSlug(objlocation: any) {
  //   try {
  //     let strlast = ""; // Initialize the last part of the slug
  //     let strdir = ""; // Initialize the directory part of the slug

  //     if (objlocation.result.cy_s) {
  //       strlast = `${objlocation.result.cy_s}`;
  //       strdir = `${objlocation.result.c_s}/${objlocation.result.s_s}/`;
  //     } else if (objlocation.result.s_s) {
  //       strlast = `${objlocation.result.s_s}`;
  //       strdir = `${objlocation.result.c_s}/`;
  //     } else if (objlocation.result.c_s) {
  //       strlast = `${objlocation.result.c_s}`;
  //     }

  //     return `b2b-list/${strdir}${strlast}-database`;
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     throw error;
  //   }
  // }

  // const isLocationSelected = !!searchLocation.location;
  // const isCategorySelected = !!searchCategory.category;

  // const getRedirectUrl = () => {
  //   // Check if searchCategory and searchLocation have valid selected values
  //   const isCategoryValid =
  //     searchCategory.result && "c" in searchCategory.result;
  //   const isLocationValid =
  //     searchLocation.result && "d" in searchLocation.result;

  //   if (isLocationSelected && isLocationValid) {
  //     if (isCategorySelected && isCategoryValid) {
  //       // Both location and valid category are selected
  //       return `/${setRedirectSlug(searchLocation, searchCategory)}`;
  //     } else {
  //       // Location is selected, but category is either invalid or not selected
  //       return `/${setRedirectLocationSlug(searchLocation)}`;
  //     }
  //   } else if (isCategorySelected && isCategoryValid) {
  //     // Location is not selected, but valid category is selected
  //     return `category/${searchCategory.category
  //       .toLowerCase()
  //       .replace(/\s+/g, "-")}-data-scraping`;
  //   } else {
  //     // Default URL or fallback
  //     return "#";
  //   }
  // };


  function normalizeString(str: string) {
    return slugify(str, { lower: true });
  }

  function setRedirectSlug(objlocation: any, objcategroy: any) {
    try {
      let strlast = normalizeString(`${objcategroy.result.s}`); // Normalize category name
      let strdir = "";
  
      if (objlocation.result.cy_s) {
        strlast = `list-of-${strlast}-in-${normalizeString(objlocation.result.cy_s)}`; // Normalize city
        strdir = `${normalizeString(objlocation.result.c_s)}/${normalizeString(objlocation.result.s_s)}/`; // Normalize location parts
      } else if (objlocation.result.s_s) {
        strlast = `list-of-${strlast}-in-${normalizeString(objlocation.result.s_s)}`; // Normalize state
        strdir = `${normalizeString(objlocation.result.c_s)}/`; // Normalize country
      } else if (objlocation.result.c_s) {
        strlast = `list-of-${strlast}-in-${normalizeString(objlocation.result.c_s)}`; // Normalize country
      }
  
      return `b2b-database/${strdir}${strlast}`;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error; // Propagate the error
    }
  }
  
  function setRedirectLocationSlug(objlocation: any) {
    try {
      let strlast = ""; // Initialize the last part of the slug
      let strdir = ""; // Initialize the directory part of the slug
  
      if (objlocation.result.cy_s) {
        strlast = normalizeString(`${objlocation.result.cy_s}`); // Normalize city
        strdir = `${normalizeString(objlocation.result.c_s)}/${normalizeString(objlocation.result.s_s)}/`; // Normalize location parts
      } else if (objlocation.result.s_s) {
        strlast = normalizeString(`${objlocation.result.s_s}`); // Normalize state
        strdir = `${normalizeString(objlocation.result.c_s)}/`; // Normalize country
      } else if (objlocation.result.c_s) {
        strlast = normalizeString(`${objlocation.result.c_s}`); // Normalize country
      }
  
      return `b2b-list/${strdir}${strlast}-database`;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error; // Propagate the error
    }
  }
  
  const isLocationSelected = !!searchLocation.location;
  const isCategorySelected = !!searchCategory.category;
  
  const getRedirectUrl = () => {
    // Check if searchCategory and searchLocation have valid selected values
    const isCategoryValid =
      searchCategory.result && "c" in searchCategory.result;
    const isLocationValid =
      searchLocation.result && "d" in searchLocation.result;
  
    if (isLocationSelected && isLocationValid) {
      if (isCategorySelected && isCategoryValid) {
        // Both location and valid category are selected
        return `/${setRedirectSlug(searchLocation, searchCategory)}`;
      } else {
        // Location is selected, but category is either invalid or not selected
        return `/${setRedirectLocationSlug(searchLocation)}`;
      }
    } else if (isCategorySelected && isCategoryValid) {
      // Location is not selected, but valid category is selected
      // return `category/${normalizeString(searchCategory.category)}-data-scraping`;
      return `b2b-database/list-of-${normalizeString(searchCategory.category)}-in-united-states`;
    } else {
      // Default URL or fallback
      return "#";
    }
  };

  

  // useEffect(() => {
  //   if (userLocation?.country_name && !inputModified) {
  //     setSearchLocation((prev) => ({
  //       ...prev,
  //       location: userLocation.country_name,
  //     }));
  //   }
  // }, [userLocation, inputModified, setSearchLocation]);

  useEffect(() => {
    if (!inputModified) {
      setSearchLocation((prev) => ({
        ...prev,
        location: userLocation?.country_name || "united states", // Set a default value here
      }));
    }
  }, [userLocation, inputModified, setSearchLocation]);

  useEffect(() => {
    if (searchLocation.location && searchResultsLocation.length > 0) {
      const matchingResult = searchResultsLocation.find(
        (result) => result.d === searchLocation.location
      );
      if (matchingResult) {
        setSearchLocation((prev) => ({
          ...prev,
          location: matchingResult.d,
          result: matchingResult,
        }));
        setSearchResultsLocation([]);
      }
    }
  }, [
    searchResultsLocation,
    searchLocation.location,
    setSearchLocation,
    setSearchResultsLocation,
  ]);

  const clearInput = () => {
    setSearchLocation({
      location: "", // Clear both user input and default value
      result: {},
    });
    setSearchResultsLocation([]);
    setInputModified(false);
  };

  // const clearCatagoryInput = () => {
  //   setSearchCategory({
  //     category: "", // Clear both user input and default value
  //     result: {},
  //   });
  //   setSearchResultsCategory([]);
  // };

  return (
    <div>
      {/* section1 */}
      <div className="flex items-center justify-center p-4">
        <section className="relative w-full max-w-screen-xl h-[500px] flex flex-col items-center justify-center p-4">
          {/* Pseudo-element for background image with reduced opacity */}
          <div className="absolute inset-0 mt-14 sm:w-auto md:w-auto lg:w-auto flex items-center justify-center sm:ml-4 md:ml-12 lg:ml-24">
            <Image
              src={world_map}
              alt="World Map"
              fill // Use fill to make the image fill the parent container
              className="z-0 object-contain opacity-50"
              loading="eager"
              priority // Use priority to mark this image as high priority
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw" // Responsive sizes
            />
          </div>

          <div className="text-[var(--title-color)] text-center relative z-10">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-[var(--title-color)]">
              <span className="text-[var(--title-color)]">Your Source For</span>{" "}
              <span className="gradient-new-text">Premium B2B Database</span>
            </h1>
            <p className="mt-4 text-sm sm:text-lg">
              Search sales databases, emails, phone numbers, and other vital
              details – Reliable, up-to-date, and easily accessible.
            </p>
            <div className="flex items-center justify-center">
              <div className="mt-8 flex flex-col sm:flex-row items-center justify-center w-full max-w-3xl">
                <div className="flex flex-col md:flex-row items-center px-4 justify-center max-w-xl space-x-2 md:space-y-0  md:py-0 py-4  md:bg-white md:rounded-full rounded-lg shadow-lg mb-4 sm:mb-0 w-full">
                  <div className="relative flex p-2 space-x-4 font-semibold flex-grow">
                    <label
                      htmlFor="location-search"
                      className="flex flex-col items-center justify-center md:border-e-2 md:border-b-0 border-b-2 md:p-0 p-4 md:pr-12"
                    >
                      <span className="text-[var(--title-color)]">
                        Location
                      </span>
                      <div className="relative w-full">
                        <input
                          type="text"
                          name="location"
                          autoComplete="off"
                          value={searchLocation.location}
                          onChange={(e) => {
                            setInputModified(true);
                            if (e.target.value.length > 0) {
                              setSearchLocation({
                                ...searchLocation,
                                [e.target.name]: e.target.value,
                              });
                              // Close category dropdown when typing in location
                              setSearchResultsCategory([]); // Add this line
                              setHighlightedCatagory(-1); // Add this line
                              // Fetch search results here
                            } else {
                              setSearchLocation({
                                ...searchLocation,
                                [e.target.name]: "",
                                result: {},
                              });
                              setSearchResultsLocation([]);
                              setHighlightedIndex(-1); // Reset highlighted index
                            }
                          }}
                          id="location-search"
                          placeholder="Search Location"
                          className="mt-1 text-sm outline-none text-center text-[var(--title-color)] w-full placeholder:text-center pr-8 pl-8"
                          onKeyDown={handleKeyDown}
                        />
                        {/* Clear button */}
                        {searchLocation.location.length > 0 && (
                          <button
                            type="button"
                            onClick={clearInput}
                            className="absolute inset-y-0 right-0 flex items-center p-3 md:pr-4 md:py-2"
                            aria-label="Clear"
                          >
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 1024 1024"
                              fillRule="evenodd"
                              className="text-gray-500"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M799.855 166.312c.023.007.043.018.084.059l57.69 57.69c.041.041.052.06.059.084a.118.118 0 0 1 0 .069c-.007.023-.018.042-.059.083L569.926 512l287.703 287.703c.041.04.052.06.059.083a.118.118 0 0 1 0 .07c-.007.022-.018.042-.059.083l-57.69 57.69c-.041.041-.06.052-.084.059a.118.118 0 0 1-.069 0c-.023-.007-.042-.018-.083-.059L512 569.926 224.297 857.629c-.04.041-.06.052-.083.059a.118.118 0 0 1-.07 0c-.022-.007-.042-.018-.083-.059l-57.69-57.69c-.041-.041-.052-.06-.059-.084a.118.118 0 0 1 0-.069c.007-.023.018-.042.059-.083L454.073 512 166.371 224.297c-.041-.04-.052-.06-.059-.083a.118.118 0 0 1 0-.07c.007-.022.018-.042.059-.083l57.69-57.69c.041-.041.06-.052.084-.059a.118.118 0 0 1 .069 0c.023.007.042.018.083.059L512 454.073l287.703-287.702c.04-.041.06-.052.083-.059a.118.118 0 0 1 .07 0Z"></path>
                            </svg>
                          </button>
                        )}

                        {searchResultsLocation.length > 0 &&
                          searchLocation.location.length > 0 && (
                            <ul className="absolute z-10 lg:mt-[9px] mt-[2px] lg:w-[180px] -ml-8 w-[280px] md:-ml-0 rounded-lg lg:rounded-none scrollbar-none bg-white border-gray-300 max-h-60 overflow-auto">
                              {" "}
                              {searchResultsLocation.map((result, index) => (
                                <li
                                  key={index}
                                  className={`px-4 py-2 text-center text-sm text-[var(--title-color)] cursor-pointer ${
                                    index === highlightedIndex
                                      ? "bg-gray-100"
                                      : ""
                                  }`}
                                  onClick={() => selectItem(result)}
                                  onMouseEnter={() =>
                                    setHighlightedIndex(index)
                                  }
                                >
                                  {result.d}
                                </li>
                              ))}
                            </ul>
                          )}
                      </div>
                    </label>
                  </div>

                  <div className="relative flex-grow">
    <div className="flex flex-col py-1 px-2 md:mb-0 mb-4 rounded-lg text-gray-500 font-semibold cursor-pointer">
      <label htmlFor="category-search" className="flex flex-col items-center justify-center">
        <span className="text-[var(--title-color)]">Category</span>
        <div className="relative">
          <input
            type="text"
            id="category-search"
            name="category"
            autoComplete="off"
            onChange={(e) => {
              const value = e.target.value;
              setSearchCategory({
                ...searchCategory,
                [e.target.name]: value,
              });

              // Close location dropdown when typing in category
              setSearchResultsLocation([]);
              setHighlightedIndex(-1);

              // If the input value matches a category, set it automatically
              const matchedCategory = searchResultsCategory.find(
                (cat) => cat.c.toLowerCase() === value.toLowerCase()
              );
              if (matchedCategory) {
                handleItemSelection(matchedCategory);
              }
            }}
            value={searchCategory.category}
            placeholder="Search Category"
            className="mt-1 text-sm outline-none text-center text-[var(--title-color)] w-full placeholder:text-center px-9 md:px-4 md:py-2"
            onKeyDown={handleKeyboardNavigation}
          />
          {/* Clear button */}
          {searchCategory.category.length > 0 && (
            <button
              type="button"
              onClick={clearCatagoryInput}
              className="absolute inset-y-0 right-0 flex items-center justify-center w-8 h-8"
              aria-label="Clear"
            >
               <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 1024 1024"
                                fillRule="evenodd"
                                className="text-gray-500"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M799.855 166.312c.023.007.043.018.084.059l57.69 57.69c.041.041.052.06.059.084a.118.118 0 0 1 0 .069c-.007.023-.018.042-.059.083L569.926 512l287.703 287.703c.041.04.052.06.059.083a.118.118 0 0 1 0 .07c-.007.022-.018.042-.059.083l-57.69 57.69c-.041.041-.06.052-.084.059a.118.118 0 0 1-.069 0c-.023-.007-.042-.018-.083-.059L512 569.926 224.297 857.629c-.04.041-.06.052-.083.059a.118.118 0 0 1-.07 0c-.022-.007-.042-.018-.083-.059l-57.69-57.69c-.041-.041-.052-.06-.059-.084a.118.118 0 0 1 0-.069c.007-.023.018-.042.059-.083L454.073 512 166.371 224.297c-.041-.04-.052-.06-.059-.083a.118.118 0 0 1 0-.07c.007-.022.018-.042.059-.083l57.69-57.69c.041-.041.06-.052.084-.059a.118.118 0 0 1 .069 0c.023.007.042.018.083.059L512 454.073l287.703-287.702c.04-.041.06-.052.083-.059a.118.118 0 0 1 .07 0Z"></path>
                              </svg>
            </button>
          )}
          {searchResultsCategory.length > 0 && searchCategory.category.length > 0 && (
            <ul className="absolute z-10 mt-4 md:-ml-0 lg:mt-1 -ml-8 w-[280px] lg:w-full scrollbar-none bg-white lg:rounded-none rounded-lg max-h-60 overflow-auto">
              {searchResultsCategory.map((result, index) => (
                <li
                  key={index}
                  className={`px-4 py-2 text-center text-sm text-[var(--title-color)] cursor-pointer ${
                    index === highlightedCatagory ? "bg-gray-100" : ""
                  }`}
                  onClick={() => handleItemSelection(result)}
                  onMouseEnter={() => setHighlightedCatagory(index)}
                >
                  {result.c.toLowerCase()}
                </li>
              ))}
            </ul>
          )}
        </div>
      </label>
    </div>
  </div>
                  <div className="md:pl-4 sm:pl-12">
                    <Link href={getRedirectUrl()}>
                      <button
                        aria-label={
                          !isLocationSelected && !isCategorySelected
                            ? "Search (disabled)"
                            : "Search button"
                        }
                        className={`bg-[#11181c] hover:bg-[#000000] w-12 h-12 flex items-center justify-center text-white font-semibold rounded-full hover:shadow-lg transition duration-300 ${
                          !isLocationSelected && !isCategorySelected
                            ? "cursor-not-allowed opacity-50" // Make disabled button visually distinct
                            : ""
                        }`}
                        disabled={!isLocationSelected && !isCategorySelected}
                      >
                        <svg
                          className="md:w-6 md:h-6 w-5 h-5" // Slightly increased SVG size for better visibility
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Hero;
